App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

img.app-logo {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.MuiDrawer-paper {
  background-color: rgb(238, 238, 238) !important;
}

.nav-link {
  text-decoration: none;
  color: black;
}

.nav-btn:hover {
  background-color: #e6ecff !important;
  border-radius: 10px;
  color: #003cff;
  font-weight: bold !important;
}

.nav-link-icon {
  color: #7f95dc;
}

.nav-btn:hover .nav-link-icon {
  color: black;
}

.css-cveggr-MuiListItemIcon-root {
  min-width: 34px !important;
}

.RichTextEditor__root___2QXK- {
  height: 680px;
  width: 500px;
  overflow-y: scroll;
}

.demo {
  font-style: italic;
  color: rgb(160, 160, 160);
}

.new-users {
  background-color: red !important;
}
